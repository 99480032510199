import BackgroundImage from "gatsby-background-image"
import { ArrowDownIcon, ArrowLongRightIcon } from "@heroicons/react/24/solid"
import React from "react"
import { FreeMode, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import ReactPlayer from "react-player"
import { PlayIcon } from "@heroicons/react/24/solid"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"

const CavingSlider = props => {
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={20}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          1536: {
            slidesPerView: 2.4,
          },
          1280: {
            slidesPerView: 2.4,
          },
          1024: {
            slidesPerView: 2.4,
          },
          768: {
            slidesPerView: 1,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        pagination={{
          el: ".my-custom-pagination-div-caving",
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"> </span>'
          },
        }}
      >
        {props.slides.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div
                className="rounded-[30px] h-[200px] lg:h-[250px] xl:h-[355px] bg-yellow/50"
                key={index}
              >

<ReactPlayer
                url={item.videourl}
                controls={true}
                playing={false}
                muted={true}
                width="100%"
                height="100%"
                playIcon={
                  <button className="p-8 rounded-full bg-white">
                    <PlayIcon className="w-12 text-brown" />
                  </button>
                }
                light={item.imageurl}
              />

                
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

export default CavingSlider
