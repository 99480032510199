import React, { useState } from "react"
import ReactPlayer from "react-player"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import VideoSlider from "../components/sliders/video-slider"
import VideoSliderSecond from "../components/sliders/video-slider-duplicate"
import BlogSlider from "../components/sliders/video-slider-three-col"
import {
  ArrowDownIcon,
  ArrowLongRightIcon,
  ArrowRightIcon,
  ClockIcon,
} from "@heroicons/react/24/solid"
import Uploader from "../components/drop-zone/uploader"
import { useForm } from "react-hook-form"
import firebase from "firebase/compat/app"
import db from "../firebaseConfig"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { PlayIcon } from "@heroicons/react/24/solid"
import CsrSlider from "../components/sliders/csr-slider"
import CavingSlider from "../components/sliders/video-caving-slider"

const Product = ({ data }) => {
  const images = {
    blogOne: getImage(data.blogOne),
    blogTwo: getImage(data.blogTwo),
    blogThree: getImage(data.blogThree),
    blogFour: getImage(data.blogFour),
    blogFive: getImage(data.blogFive),
    blogSix: getImage(data.blogSix),
    blogSeven: getImage(data.blogSeven),
    blog8: getImage(data.blog8),

    insideHeader: getImage(data.insideHeader),
    header: convertToBgImage(getImage(data.header)),

    harithaTeaBox: getImage(data.harithaTeaBox),
    leaves: getImage(data.leaves),

    benefitbg: convertToBgImage(getImage(data.beneBg)),

    //icons
    harithaicon01: getImage(data.harithaicon01),
    harithaicon02: getImage(data.harithaicon02),
    harithaicon03: getImage(data.harithaicon03),
    harithaicon04: getImage(data.harithaicon04),

    //logos
    cargeels: getImage(data.cargeels),
    daraz: getImage(data.daraz),
    glomark: getImage(data.glomark),
    keels: getImage(data.keels),
    zesta: getImage(data.zesta),

    IcedTea: convertToBgImage(getImage(data.IcedTea)),
    ginger: convertToBgImage(getImage(data.ginger)),
    tea: convertToBgImage(getImage(data.tea)),

    csrBg: convertToBgImage(getImage(data.csrBg)),
  }

  const blogs = [
    {
      image: images.blog8,
      title: "Sharing the joys of Christmas with a friendly chat",
      anchor: "/sharing-the-joys-christmas-with-friendly-chat/",
    },
    {
      image: images.blogOne,
      title: "දිරියට කෘතගුණ  Children’s Day 2022",
      anchor: "/childrens-day-22/",
    },
    {
      image: images.blogTwo,
      title: "Watawala Tea Rings in Children’s Day with Fun-Filled Event",
      anchor: "/watawala-tea-rings-in-children-day/",
    },
    {
      image: images.blogThree,
      title: "Inspiring Ethnic Harmony through Gratitude ",
      anchor: "/inspiring-ethnic-harmony-through-gratitude/",
    },
    {
      image: images.blogFour,
      title: "Watawala Scholarship Programme 2020  ",
      anchor: "/watawala-scholarship-programme-2020/",
    },
    {
      image: images.blogFive,
      title: "Watawala Tea Drinking Water Project",
      anchor: "/drinking-water-project%E2%80%93kataragama/",
    },
    {
      image: images.blogSix,
      title: "Watawala Tea Joins the Fight Against COVID-19",
      anchor: "/watawala-tea-joins-the-fight-against-covid-19/",
    },
    {
      image: images.blogSeven,
      title: "Celebrating Avurudu with the Farming Community",
      anchor: "/celebrating-avurudu-with-the-farming-community/",
    },
  ]

  const advantages = [
    {
      icon: images.harithaicon01,
      topic: "Protect the brain",
      desc: "Catechins found in green tea can reduce the risk of neurodegenerative disorders. ",
    },
    {
      icon: images.harithaicon03,
      topic: "Improve weight loss",
      desc: "Because green tea boosts the metabolic rate, it can help reduce body fat. ",
    },
    {
      icon: images.harithaicon02,
      topic: "Anti-ageing",
      desc: "Antioxidants found in green tea can help prevent cell damage and thus ageing. ",
    },
    {
      icon: images.harithaicon04,
      topic: "Combat inflammation",
      desc: "Its anti-inflammatory properties can reduce effects of autoimmune diseases.",
    },
  ]

  const caving = [
    {
      imageurl:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/product-video%2Fharitha-video-03.png?alt=media&token=f9485d15-dec6-4798-89a1-79159db5ee78",
      videourl:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/product-video%2Fharitha-video-03.mp4?alt=media&token=cab7b498-677d-4d3a-80b3-4a262c039bf8",
    },
    {
      imageurl:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/product-video%2Fharitha-video-01.png?alt=media&token=50cdf9f3-ea25-461f-a464-a30f41221f5f",
      videourl:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/product-video%2Fharitha-video-01.mp4?alt=media&token=1a541930-9ba8-488c-98cb-8158283ffb36",
    },
    {
      imageurl:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/product-video%2Fharitha-video-02.png?alt=media&token=3bf80da9-ab0d-4349-b629-75a6a668ef2d",
      videourl:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/product-video%2Fharitha-video-02.mp4?alt=media&token=fdb067ca-ae3f-4568-a6e4-b343a1afdd05",
    },
  ]

  const logo = [
    {
      image: images.daraz,
      shoplink: "https://www.daraz.lk/shop/watawala/",
    },
    {
      image: images.keels,
      shoplink: "https://www.keellssuper.com/product?cat=4&s=~WATAWALA",
    },
    {
      image: images.cargeels,
      shoplink: "https://cargillsonline.com/web/product?PS=watawala%20tea",
    },
    {
      image: images.zesta,
      shoplink: "https://www.zestaceylontea.com/product-category/watawala-tea/",
    },
    {
      image: images.glomark,
      shoplink: "https://glomark.lk/search?search-text=watawala",
    },
  ]

  return (
    <Layout>
      <Seo title="Haritha Tea | Watawala" />
      <section>
        <div>
          <div>
            <BackgroundImage
              {...images.header}
              className="w-full h-min md:h-screen  "
            >
              <div className="flex space-y-4  md:justify-between items-center h-full w-full relative z-20 container mx-auto  px-10    md:flex-row   flex-col">
              <h1 hidden>"Discover the Health and Vitality of Green Tea: Embrace Nature's Wellness Elixir"</h1>
                <div className="mt-24  md:mt-24    w-full md:w-2/5 ">
                  <div className="text-4xl md:text-6xl font-semibold text-white    text-center md:text-left">
                    Haritha Tea
                  </div>
                  <div className="py-2 text-white  text-xl	 sm:text-xl  text-center md:text-left  ">
                    Freshly plucked. Brimming with goodness
                  </div>
                  <div className="py-4  flex justify-center">
                    <button className="uppercase duration-200 font-semibold text-black bg-yellow text-center rounded-full cursor-pointer py-4 px-6 group mx-auto md:ml-0">
                      <Link to="#online">
                        <div className="flex items-center">
                          <div>Buy Now</div>
                          <div className="ml-4 group-hover:ml-6 duration-300">
                            <ArrowRightIcon className="w-5" />
                          </div>
                        </div>
                      </Link>
                    </button>
                  </div>
                </div>

                <div className="w-full md:w-3/5">
                  <GatsbyImage
                    image={images.insideHeader}
                    alt="Watawala"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="h-full w-full  absolute top-0 z-10"></div>
              {/* <div className="h-full w-full bg-[#23211B]/60 absolute top-0 z-10"></div> */}
            </BackgroundImage>
          </div>
        </div>
      </section>

      <section>
        <div className="container mx-auto md:px-20 px-10 py-20">
          <div className="flex justify-between items-center  md:flex-row   flex-col ">
            <div className="md:w-1/2 w-full relative ">
              <div className="bg-green/25   py-10 px-10  md:py-16   rounded-full relative">
                <GatsbyImage
                  image={images.harithaTeaBox}
                  alt="Watawala"
                  className="w-full"
                />
                <div className="md:w-4/6 w-4/6 absolute md:-right-10  lg:-right-20 -right-2 bottom-0 ">
                  <GatsbyImage
                    image={images.leaves}
                    alt="Watawala"
                    className=""
                  />
                </div>
              </div>
            </div>
            <div className="max-w-md  pl-0   md:pl-10">
              <div className="font-semibold text-xl py-4  text-center md:text-left">
                DESCRIPTION
              </div>
              <div className="text-base			 font-normal   leading-7		  text-justify	  sm:text-left">
                Haritha Tea is a light and airy green tea that is known for its
                freshness and delightful aroma. Loaded with antioxidants, our
                green tea combats ageing, increases fat burning and improves
                brain function so you can enjoy the triple benefits of youth,
                health and mental performance with every sip.
              </div>
              <div className="py-4 font-semibold text-xl">TASTE</div>
              <div>Floral, Earthy, Bittersweet</div>
            </div>
          </div>
        </div>
      </section>

      <section id="online">
        <div className="bg-yellow/25 py-10 ">
          <div className="container md:px-20 px-10 mx-auto py-10  ">
            <div>
              <div className="flex justify-center">
                <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                  ONLINE SHOP
                </div>
              </div>
              <div className="uppercase text-3xl text-center mx-auto mb-4 tracking-widest">
                Just a click away
              </div>
              <div className="max-w-6xl text-center mb-7 mx-auto font-light text-lg">
                Now you can order your favourite Watawala teas online and have
                it delivered right to your doorstep.
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2  lg;grid-cols-5  xl:grid-cols-5  2xl:grid-cols-5 gap-8">
              {logo.map((item, index) => {
                return (
                  <div
                    className="bg-white py-6 px-4 hover:border-yellow border border-transparent duration-500 rounded-2xl flex flex-col justify-between"
                    key={index}
                  >
                    <div className="w-3/5 mx-auto  ">
                      <GatsbyImage
                        image={item.image}
                        className="w-full "
                        alt="Watawala"
                      />
                    </div>
                    <div className="flex justify-center mt-4">
                      <div className="uppercase font-light tracking-widest">
                        <div className="">
                          <a target="_blank" href={item.shoplink}>
                            <button
                              type="submit"
                              className=" tracking-wide uppercase border border-yellow bg-yellow text-black  font-semibold rounded-full px-6 py-2 hover:shadow-md duration-300 flex gap-3 items-center cursor-pointer"
                            >
                              shop now
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section>
        <BackgroundImage {...images.benefitbg}>
          <div className="container mx-auto   px-10 py-20">
            <div className="flex justify-between gap-20 items-center      md:flex-row   flex-col">
              <div>
                <div className="flex place-content-center	md:place-content-start">
                  <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                    BENEFITS
                  </div>
                </div>
                <div className="uppercase text-3xl   mb-4 tracking-widest max-w-full	md:max-w-md   text-center md:text-left">
                  A Superfood for the Body and the Mind
                </div>

                <div className="grid grid-cols-1 md:grid-cols-1  lg:grid-cols-2   xl:grid-cols-2    2xl:grid-cols-2  gap-10  pt-10">
                  {advantages.map((item, index) => {
                    return (
                      <div className="flex gap-4 items-center">
                        <div className="bg-yellow py-8 px-8  rounded-full">
                          <div className="w-8 md:w-14">
                            <GatsbyImage image={item.icon} className="w-full" />
                          </div>
                        </div>

                        <div>
                          <div className=" font-semibold pb-2">
                            {item.topic}
                          </div>
                          <div>{item.desc}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="bg-blue-300/25  w-full lg:w-3/5  "></div>
            </div>
          </div>
        </BackgroundImage>
      </section>

      <section>
        <div className="container mx-auto  px-10 py-10">
          <div>
            <div className="flex justify-center">
              <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                KNOWLEDGE
              </div>
            </div>
            <div className="uppercase text-3xl text-center mx-auto mb-4 tracking-widest">
              Learn more about Haritha Tea
            </div>
            <div className="max-w-6xl text-center mb-14 mx-auto font-light text-lg">
              Learn everything you need to know about Haritha Tea, from how to
              prepare the perfect cup of our green tea to how to use it to
              improve your wellness and lifestyle.
            </div>
          </div>

          <div>
            <CavingSlider slides={caving} />
            <div className="flex z-10 mb-5 w-full justify-center mt-10">
              <div className="flex gap-3">
                <div className=" my-custom-pagination-div-caving"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-yellow/50">
          <BackgroundImage {...images.csrBg}>
            <div className="container md:px-20 px-10 mx-auto py-20 overflow-hidden">
              <div className="mb-14">
                <div className="flex justify-center">
                  <div>
                    <div className="flex justify-center">
                      <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                        watawala CSR
                      </div>
                    </div>
                    <div className="uppercase text-3xl max-w-3xl leading-normal text-center mx-auto mb-4 tracking-widest">
                      Empowering a nation, one step at a time
                    </div>
                    <div className="max-w-4xl text-center mx-auto font-light text-lg">
                      We believe in contributing towards society by supporting
                      the underprivileged in areas they need the most help.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <CsrSlider slides={blogs} />
              </div>
            </div>
          </BackgroundImage>
        </div>
      </section>
    </Layout>
  )
}

export default Product

export const query = graphql`
  query ProductpageImages {
    header: file(relativePath: { eq: "product/harithahHeaderBg.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    insideHeader: file(relativePath: { eq: "product/harithaTeaInside.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    leaves: file(relativePath: { eq: "product/leaves.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    harithaTeaBox: file(relativePath: { eq: "product/harithaTeaBox.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    cargeels: file(relativePath: { eq: "product/logo/cargeels.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    daraz: file(relativePath: { eq: "product/logo/daraz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    glomark: file(relativePath: { eq: "product/logo/glomark.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    keels: file(relativePath: { eq: "product/logo/keels.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    zesta: file(relativePath: { eq: "product/logo/zesta.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    beneBg: file(relativePath: { eq: "product/benefit-Section-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    harithaicon01: file(
      relativePath: { eq: "product/haritha-icons/icon1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    harithaicon02: file(
      relativePath: { eq: "product/haritha-icons/icon2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    harithaicon03: file(
      relativePath: { eq: "product/haritha-icons/icon3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    harithaicon04: file(
      relativePath: { eq: "product/haritha-icons/icon4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogOne: file(relativePath: { eq: "pages/news/csrnews1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogTwo: file(relativePath: { eq: "pages/news/csrnews2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogThree: file(relativePath: { eq: "pages/news/csrnews3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogFour: file(relativePath: { eq: "pages/news/csrnews4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogFive: file(relativePath: { eq: "pages/news/csrnews5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogSix: file(relativePath: { eq: "pages/news/csrnews6.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blogSeven: file(relativePath: { eq: "pages/news/csrnews7.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    blog8: file(relativePath: { eq: "pages/news/csrnews8.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }





    IcedTea: file(relativePath: { eq: "pages/home/IcedTea.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    ginger: file(relativePath: { eq: "pages/home/ginger.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    tea: file(relativePath: { eq: "pages/home/tea.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    csrBg: file(relativePath: { eq: "pages/home/csrBg.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
